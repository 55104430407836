import React, { useState } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import { combinedAnalytics } from 'src/utils/common'
import styles from './styles.module.scss'

const Wrapper = ({ childrenMenuItems, ...restProps }) => {
  if (childrenMenuItems) {
    return (
      <div className={clsx(styles.mobileMenuItem)} {...restProps}>
        {restProps.children}
      </div>
    )
  }

  return (
    <a
      className={clsx(styles.mobileMenuItem, styles.mobileMenuItemSingle)}
      {...restProps}
    >
      {restProps.title}
    </a>
  )
}

export const MobileMenuItem = ({ path, childrenMenuItems, title }) => {
  const [openChildren, setOpenChildren] = useState(false)
  const childrenItemClick = () => setOpenChildren(!openChildren)

  return (
    <Wrapper href={path} title={title} childrenMenuItems={childrenMenuItems}>
      {childrenMenuItems && (
        <div
          className={clsx(styles.menuItem, {
            [styles.mobileMenuItemBorder]: openChildren,
          })}
        >
          <div className={styles.text} onClick={childrenItemClick}>
            {title}
            <Image
              src={`/assets/icons/${
                openChildren
                  ? 'icon-arrow-up-new.svg'
                  : 'icon-arrow-down-new.svg'
              }`}
              width='24'
              height='24'
              alt='arrow icon'
            />
          </div>
          <div
            className={clsx(styles.childrenContainer, {
              [styles.childrenContainerOpen]: openChildren,
            })}
          >
            {childrenMenuItems.map(item => {
              if (item.type === 'separator') {
                return <div key={item.key} className={styles.separator} />
              }
              if (item.notMobile) return null
              return (
                <a
                  onClick={() =>
                    combinedAnalytics(
                      'Menu',
                      'Menu',
                      'Interaction',
                      item.title ? item.title : item.key
                    )
                  }
                  key={item.key}
                  href={item.path}
                  className={clsx(styles.childrenText)}
                >
                  {item.title}
                </a>
              )
            })}
          </div>
        </div>
      )}
    </Wrapper>
  )
}

export default MobileMenuItem
