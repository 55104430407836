import { useState } from 'react'
import Select, { components } from 'react-select'
import { AutoSizer, List } from 'react-virtualized'
import compact from 'lodash/compact'
import Image from 'next/image'
import { ENTITY } from 'src/consts'
import { selectStyles } from './selectStyles'
import styles from './styles.module.scss'

const MenuList = props => {
  const rowRenderer = ({ key, index, style }) => {
    return (
      <div
        key={key}
        className={styles.row}
        style={style}
        onClick={() =>
          props.setValue(props.children[index].props.data, 'set-value')
        }
      >
        <div className={styles.left}>
          <span className={styles.img}>
            <Image
              width='16'
              height='16'
              src={`${process.env.NEXT_PUBLIC_API_ENDPOINT}/icons/${
                props.entity
              }s/64x64/${props.children[index].props.data.name
                .toLowerCase()
                .replace(' ', '-')}.png`}
              alt='price, market cap on Coin360 heatmap'
            />
          </span>
          {props.children[index].props.data.name}
        </div>
        <div className={styles.right}>
          {props.children[index].props.data.symbol}
        </div>
      </div>
    )
  }

  return (
    <components.MenuList {...props}>
      <AutoSizer>
        {({ width }) => (
          <List
            width={width}
            height={props.children.length ? 150 : 0}
            rowCount={props.children.length || 0}
            rowHeight={50}
            rowRenderer={rowRenderer}
          />
        )}
      </AutoSizer>
    </components.MenuList>
  )
}

export const MultiSelectFilter = ({
  label,
  addItem,
  deleteItem,
  clear,
  mapDataArray,
  currentItemsDict,
  entity = ENTITY.COIN,
  ...props
}) => {
  const options = mapDataArray.filter(l => !currentItemsDict[l.slug])
  const items = compact(
    Object.keys(currentItemsDict).map(sym =>
      mapDataArray.find(d => d.slug === sym)
    )
  )

  const [value, setValue] = useState(items)

  const handleChange = (val, actionMeta) => {
    switch (actionMeta.action) {
      case 'set-value':
        addItem(val.slug)
        val = [...value, val]
        break
      case 'remove-value':
        deleteItem(actionMeta.removedValue.slug)
        val = value.filter(
          item => item.symbol !== actionMeta.removedValue.symbol
        )
        break
      case 'clear':
        clear()
        setValue([])
        break
    }
    setValue(val)
  }

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <Select
        onChange={handleChange}
        options={options}
        value={value}
        styles={selectStyles}
        components={{
          MenuList: menuProps => <MenuList {...menuProps} entity={entity} />,
        }}
        getOptionLabel={option => option.symbol}
        getOptionValue={option => option.slug}
        isMulti
        isSearchable
        {...props}
      />
    </div>
  )
}

export default MultiSelectFilter
