export const selectStyles = {
  container: provided => ({
    ...provided,
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    backgroundColor: 'transparent',
    minHeight: '30px',
    boxShadow: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.4)',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: 'hsla(240, 2%, 12%, 0.95)',
    marginTop: '1px',
    borderRadius: 0,
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    maxHeight: '200px',
  }),
  option: provided => ({
    ...provided,
    padding: '6px 6px 6px 10px',
    background: 'transparent',
    lineHeight: '16px',
    color: 'rgba(255, 255, 255, 0.6)',
  }),
}
