import Image from 'next/image'
import styles from './styles.module.scss'

export const FiltersTrigger = ({ visible }) => {
  return (
    <div className={styles.trigger}>
      <Image
        src={
          visible
            ? '/assets/icons/icon-filters-back-icon.svg'
            : '/assets/icons/icon-filters-icon.svg'
        }
        width='16'
        height='16'
        alt={`${visible ? 'Hide' : 'Show'} filters`}
      />
    </div>
  )
}

export default FiltersTrigger
