import clsx from 'clsx'
import Image from 'next/image'
import { combinedAnalytics } from 'src/utils/common'
import styles from './styles.module.scss'

export const SocialBlock = ({ transparentBackground, footer }) => {
  const handleSocialInteraction = e => {
    combinedAnalytics(
      'Outbound Links',
      'Outbound Links',
      'Social',
      e.currentTarget.href
    )
  }

  return (
    <div className={clsx(styles.headerSocial, { transparentBackground })}>
      {footer && (
        <a
          target='_blank'
          rel='noopener noreferrer nofollow'
          href='mailto:contact@coin360.com'
          className={clsx(styles.item, styles.mailIcon)}
        >
          <Image
            src='/assets/social/icon-mail.svg'
            width='20'
            height='16'
            alt='Telegram icon'
          />
        </a>
      )}
      <a
        target='_blank'
        rel='noopener noreferrer nofollow'
        href='https://t.me/COIN360Channel'
        onClick={handleSocialInteraction}
        className={styles.item}
      >
        <Image
          src='/assets/social/icon-telegram.svg'
          width='20'
          height='16'
          alt='Telegram icon'
        />
      </a>
      <a
        target='_blank'
        rel='noopener noreferrer nofollow'
        href='https://twitter.com/coin360com'
        onClick={handleSocialInteraction}
        className={styles.item}
      >
        <Image
          src='/assets/social/icon-twitter.svg'
          width='20'
          height='16'
          alt='Twitter icon'
        />
      </a>
    </div>
  )
}

export default SocialBlock
