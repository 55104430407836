import { createRef, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ENTITY } from 'src/consts'
import { getRemoteMapData } from 'src/redux/thunks'
import { toggleFullScreen } from 'src/redux/actions'
import { getFullScreenState } from 'src/redux/selectors'
import { cancelFullScreen } from '../TreeMap/helpers'
import { MobileHeader, DesktopHeader } from './components'

export const Header = ({
  fixed = false,
  switcherWatchlist = false,
  filterToggle,
}) => {
  const dispatch = useDispatch()
  const fullScreen = useSelector(getFullScreenState)

  const handleKeyPress = event => {
    if (event.keyCode === 27) {
      cancelFullScreen()
      dispatch(toggleFullScreen(!fullScreen))
    }
  }

  const hangleFullScreenChange = useCallback(() => {
    const isDocumentFullScreen =
      !!document.fullscreenElement ||
      !!document.mozFullScreenElement ||
      !!document.webkitFullscreenElement

    dispatch(toggleFullScreen(isDocumentFullScreen))
  }, [dispatch])

  useEffect(() => {
    document.addEventListener('keypress', handleKeyPress)

    document.addEventListener('webkitfullscreenchange', hangleFullScreenChange)
    document.addEventListener('mozfullscreenchange', hangleFullScreenChange)
    document.addEventListener('fullscreenchange', hangleFullScreenChange)
    document.addEventListener('MSFullscreenChange', hangleFullScreenChange)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refRootNode = createRef()

  if (fullScreen) return null

  return (
    <>
      <MobileHeader
        switcherWatchlist={switcherWatchlist}
        filterToggle={filterToggle}
      />
      <DesktopHeader
        refNode={refRootNode}
        fullScreen={fullScreen}
        fixed={fixed}
      />
    </>
  )
}

export default Header
