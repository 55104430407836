import { useState, useRef, useEffect } from 'react'
import Select from 'react-select'
import Image from 'next/image'
import useOnClickOutside from 'use-onclickoutside'
import clsx from 'clsx'
import { v4 as uuidv4 } from 'uuid'
import { selectStyles } from './selectStyles'
import styles from './styles.module.scss'

export const SelectBox = ({
  className,
  label,
  options,
  action,
  value,
  defaultValue,
  components,
  isFilter,
  ...props
}) => {
  const [stateValue, setStateValue] = useState(
    options.filter(x => x.value === value)
  )
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setStateValue(options.filter(x => x.value === value))
  }, [value, options])

  const handleChange = val => {
    setStateValue(val)
    action && action(val.value)
  }

  const ref = useRef(null)

  const toggleOpen = () => setIsOpen(!isOpen)

  const handleClose = () => setIsOpen(false)

  useOnClickOutside(ref, handleClose)

  //TODO: Need to improve this in next version
  return isFilter ? (
    <div
      className={clsx(styles.wrapper, className)}
      onClick={toggleOpen}
      ref={ref}
    >
      {label && <span className={styles.label}>{label}</span>}
      <Select
        inputId={uuidv4()}
        instanceId={uuidv4()}
        onChange={handleChange}
        value={stateValue}
        defaultValue={options.filter(x => x.value === defaultValue)}
        options={options}
        menuIsOpen={isOpen}
        styles={selectStyles}
        isSearchable={false}
        className={clsx({ [styles.active]: value !== defaultValue })}
        classNamePrefix='react-select'
        components={{
          DropdownIndicator: () => (
            <Image
              src={`/assets/icons/icon-arrow-${isOpen ? 'up' : 'down'}.svg`}
              width='7'
              height='7'
              alt='Arrow icon'
            />
          ),
          ...components,
        }}
        {...props}
      />
    </div>
  ) : (
    <div className={clsx(styles.wrapper, className)}>
      {label && <span className={styles.label}>{label}</span>}
      <Select
        inputId={uuidv4()}
        instanceId={uuidv4()}
        onChange={handleChange}
        value={stateValue}
        defaultValue={options.filter(x => x.value === defaultValue)}
        options={options}
        styles={selectStyles}
        isSearchable={false}
        className={clsx({ [styles.active]: value !== defaultValue })}
        classNamePrefix='react-select'
        components={{
          DropdownIndicator: () => (
            <Image
              src={`/assets/icons/icon-arrow-${isOpen ? 'up' : 'down'}.svg`}
              width='7'
              height='7'
              alt='Arrow icon'
            />
          ),
          ...components,
        }}
        {...props}
      />
    </div>
  )
}
