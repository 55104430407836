import { useState, useEffect } from 'react'
import Select from 'react-select'
import { selectStyles } from './selectStyles'
import styles from './styles.module.scss'

export const SelectFilter = ({
  label,
  options,
  action,
  value,
  defaultValue,
  ...props
}) => {
  const [stateValue, setStateValue] = useState(
    options.filter(x => x.value === value)
  )

  useEffect(() => {
    setStateValue(options.filter(x => x.value === value))
  }, [value, options])

  const handleChange = val => {
    setStateValue(val)
    action && action(val.value)
  }

  return (
    <div className={styles.wrapper}>
      {label && <div className={styles.label}>{label}</div>}
      <Select
        onChange={handleChange}
        value={stateValue}
        defaultValue={options.filter(x => x.value === defaultValue)}
        options={options}
        styles={selectStyles}
        isSearchable={false}
        {...props}
      />
    </div>
  )
}

export default SelectFilter
