import styles from './styles.module.scss'
import clsx from 'clsx'

export const RadioGroupTabs = ({
  label,
  options,
  className,
  onChange,
  value,
}) => (
  <div className={clsx(styles.wrapper, className, 'unselectable')}>
    <div className={styles.label}>{label}</div>
    <div className={styles.container}>
      {options.map(item => (
        <div
          key={item.value}
          className={clsx(styles.itemContainer, {
            [styles.isChecked]: item.value === value,
          })}
        >
          <input
            className={styles.radioItem}
            type='radio'
            id={item.value}
            value={item.value}
            checked={item.value === value}
            onChange={() => onChange(item.value)}
          />
          <label className={styles.labelItem} htmlFor={item.value}>
            {item.label}
          </label>
        </div>
      ))}
    </div>
  </div>
)

export default RadioGroupTabs
