import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import {
  getSharedFiltersCurrency,
  getGlobals,
  getGlobalsExchanges,
} from 'src/redux/selectors'
import CoinFilters from './CoinFilters'
import styles from './styles.module.scss'
import ExchangesFilters from './ExchangesFilters'

export const MobileFilterLayout = () => {
  const router = useRouter()
  const data = {
    currency: useSelector(getSharedFiltersCurrency),
    globals: useSelector(
      router.pathname.includes('/exchange') ? getGlobalsExchanges : getGlobals
    ),
  }

  return (
    <div className={styles.filter}>
      {router.pathname.includes('/exchange') ? (
        <ExchangesFilters {...data} />
      ) : (
        <CoinFilters {...data} />
      )}
    </div>
  )
}

export default MobileFilterLayout
