import React from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import SharePage from 'src/components/SharePage'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { intl } from 'src/i18n'
import styles from './styles.module.scss'

export const NewShareButton = () => {
  const share = useOverlayTriggerState({})

  return (
    <>
      <SharePage open={share.isOpen} onClose={share.close} />
      <div className={clsx(styles.shareButton)} onClick={share.open}>
        <Image
          src='/assets/social/icon-share.svg'
          width='24'
          height='24'
          alt='Share icon'
        />
        <div className={clsx(styles.children)}>
          {intl.t('components.footerMobile.share')}
        </div>
      </div>
    </>
  )
}
