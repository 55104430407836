import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import { getAllMenuItems } from 'src/components/Header/helpers/menuItems'
import styles from './styles.module.scss'

const menu = getAllMenuItems({}).find(e => e.key === 'about').children

export const AboutLayout = ({ children }) => {
  const router = useRouter()
  return (
    <div className={styles.about}>
      <div className={styles.menu}>
        <ul className={styles.list}>
          {menu.map(item => (
            <li
              className={clsx(
                styles.item,
                { [styles.active]: router.pathname === item.path },
                { [styles.break]: item.breakAfter }
              )}
              key={item.key}
            >
              {item.external ? (
                <a
                  className={styles.link}
                  href={item.path}
                  rel='noopener noreferrer'
                >
                  {item.text}
                </a>
              ) : (
                <Link href={item.path} legacyBehavior>
                  <a className={styles.link}>{item.text}</a>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  )
}

export default AboutLayout
