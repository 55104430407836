import React from 'react'
import clsx from 'clsx'
import Link from 'next/link'
import { intl } from 'src/i18n'
import { formatGlobals } from 'src/utils/currency'
import styles from './styles.module.scss'

export const NewsListGlobals = ({
  period: globalsPeriod,
  globals,
  currency,
}) => {
  const {
    globalMarketCapLong,
    globalMarketCapChange,
    globalVolumeLong,
    globalVolumeChange,
    volumeDirection,
    mCapDirection,
  } = formatGlobals(globals, currency, globalsPeriod)

  return (
    <div className={styles.newsListGlobals}>
      <Link
        className={styles.link}
        target='_blank'
        rel='noopener nofollow noreferrer'
        href='/charts'
        prefetch={false}
        passHref
      >
        <div className={styles.item}>
          <div className={styles.marketData}>
            <div>
              {intl.t(
                'components.newsFeed.components.newsListGlobals.marketCap'
              )}
            </div>
            <div className={clsx(styles.amount, styles[mCapDirection])}>
              {globalMarketCapLong ? globalMarketCapLong : '?'}
            </div>
          </div>
          <div className={styles.change}>
            <div>
              {intl.t('components.newsFeed.components.newsListGlobals.change')}
            </div>
            <div className={clsx(styles.currChange, styles[mCapDirection])}>
              {globalMarketCapChange}
            </div>
          </div>
        </div>
      </Link>
      <Link
        className={styles.link}
        target='_blank'
        rel='noopener nofollow noreferrer'
        href='/charts'
        prefetch={false}
        passHref
      >
        <div className={styles.item}>
          <div className={styles.marketData}>
            <div>
              {intl.t(
                'components.newsFeed.components.newsListGlobals.volume24'
              )}
            </div>
            <div className={clsx(styles.amount, styles[volumeDirection])}>
              {globalVolumeLong ? globalVolumeLong : '?'}
            </div>
          </div>
          <div className={styles.change}>
            <div>
              {intl.t('components.newsFeed.components.newsListGlobals.change')}
            </div>
            <div className={clsx(styles.currChange, styles[volumeDirection])}>
              {globalVolumeChange}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default NewsListGlobals
