import React from 'react'
import clsx from 'clsx'
import styles from './styles.module.scss'
import Link from 'next/link'
import queryString from 'query-string'
import Image from 'next/image'
import { useRouter } from 'next/router'

type ItemProps = {
  item: any
}

export const Item = ({ item }: ItemProps) => {
  const router = useRouter()
  const currentLocationPath = router.pathname.split('/')[1]

  const getUrl = (item: any) => {
    const { path, withQueryParams } = item
    const query = router.query

    if (!router.pathname.split('/').find(e => e === '[slug]')) {
      if (router.pathname.includes(path)) {
        const searchString = queryString.stringify(query)
        if (!query) return path
        return withQueryParams ? `${path}?${searchString}` : path
      } else if (query['currency']) {
        const currency = { currency: query['currency'] }
        const searchString = queryString.stringify(currency)
        return withQueryParams ? `${path}?${searchString}` : path
      }
    }

    return path
  }

  const handleWatchlistNavigation = e => {
    e.preventDefault() // Prevent default link behavior
    window.location.href = getUrl(item) // Force reload to the item's URL
  }

  // Only show "BETA" text if item.beta is truthy
  const betaTag = item.beta ? (
    <span className={styles.betaTag}>BETA</span>
  ) : null

  const { type } = item
  const isCurrentPage = item.key.find(key => key === currentLocationPath)

  // Temporary solution to handle the watchlist item
  const isWatchlistItem = item.path === '/watchlist'

  if (type === 'menuItem') {
    if (isWatchlistItem) {
      // Special handling for the watchlist item
      return (
        <a
          href={getUrl(item)}
          onClick={handleWatchlistNavigation}
          className={clsx(styles.dropdownParent, {
            [styles.dropdownParentActive]: isCurrentPage,
          })}
        >
          <div className={styles.menuItemContainer}>
            {item.title}
            {betaTag}
          </div>
        </a>
      )
    } else {
      // Normal handling for other items:
      // Conditionally render the new-logo.svg IF `item.beta` is set.
      // Otherwise, show `item.title`.
      return (
        <Link href={getUrl(item)} rel='nofollow' legacyBehavior>
          <a
            className={clsx(styles.dropdownParent, {
              [styles.dropdownParentActive]: isCurrentPage,
            })}
          >
            <div className={styles.menuItemContainer}>
              {item.beta ? (
                <>
                  <Image
                    src='/assets/new-logo.svg'
                    height={20}
                    width={120}
                    alt='newlogo'
                  />
                  {betaTag}
                </>
              ) : (
                item.title
              )}
            </div>
          </a>
        </Link>
      )
    }
  }

  // If it's not a menuItem, render as a dropdown
  return (
    <div className={styles.dropdown}>
      <span
        className={clsx(styles.dropdownParent, {
          [styles.dropdownParentActive]:
            (currentLocationPath === '' && item.key[0] === '/') ||
            isCurrentPage,
        })}
      >
        {!!item.path ? (
          <Link href={getUrl(item)} key={item.title}>
            {item.title}
          </Link>
        ) : (
          item.title
        )}
      </span>
      <div className={styles.dropdownContent}>
        {item.subMenu.map(subItem => {
          if (subItem.type === 'separator') {
            return <div key={subItem.key} className={styles.separator} />
          }

          return (
            <Link href={getUrl(subItem)} key={subItem.title} legacyBehavior>
              <a className={styles.dropdownItem}>{subItem.title}</a>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
