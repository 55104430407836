import styles from './styles.module.scss'
import clsx from 'clsx'

export const TooltipWrapper = ({
  children,
  content,
  className,
  classNameContent,
  classNameChildren,
  style,
}) => (
  <div className={clsx(styles.tooltipWrapper, classNameChildren)} style={style}>
    <div>{children}</div>
    <div className={clsx(styles.tooltipBlock, className)}>
      <div className={clsx(styles.tooltipContent, classNameContent)}>
        {content}
      </div>
    </div>
  </div>
)

export default TooltipWrapper
