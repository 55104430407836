import { Globals, MultiSelectFilter, SelectFilter } from './components'
import styles from './styles.module.scss'
import {
  resetExchangesFiltersAll,
  setExchangesGroup,
  setExchangesMarketsRange,
  setExchangesTop,
  changeExchangesPeriod,
  setExchangesRange,
  setSharedCurrency,
  addExchangesException,
  deleteExchangesException,
  resetExchangesExceptions,
  resetExchangesRange,
} from 'src/redux/thunks'
import {
  ENTITY,
  exchangesFiltersOptions,
  sharedFiltersOptions,
} from 'src/consts'

import { Checkbox } from 'src/components/Checkbox'
import { LogarithmicRangeFilter } from 'src/components/LogarithmicRangeFilter'
import { RadioGroupTabs } from 'src/components/RadioGroupTabs'
import { RangeFilter } from 'src/components/RangeFilter'
import { useDispatch, useSelector } from 'react-redux'
import {
  getDependsOnByEntity,
  getExchangesActivePeriod,
  getExchangesExceptionsDict,
  getExchangesGroup,
  getExchangesMapDataArrayRaw,
  getExchangesMapDataMaxValues,
  getExchangesMarketsRange,
  getExchangesPeriod,
  getExchangesRange,
  getExchangesTop,
} from 'src/redux/selectors'
import { getFieldOfDataObject } from 'src/utils/currency'
import { resetSharedFiltersAction } from 'src/redux/actions'
import { sharedFiltersDefaults } from 'src/redux/reducers'
import { intl } from 'src/i18n'

export const ExchangesFilters = ({ currency, globals }) => {
  const dispatch = useDispatch()
  const exchangesRange = useSelector(getExchangesRange)
  const exchangesMarketRange = useSelector(getExchangesMarketsRange)
  const coinsDepends = useSelector(getDependsOnByEntity)
  const maxValueVolumeRange = getFieldOfDataObject(
    useSelector(getExchangesMapDataMaxValues),
    coinsDepends,
    Math.round
  )
  const maxValueMarkets = getFieldOfDataObject(
    useSelector(getExchangesMapDataMaxValues),
    'pairs'
  )
  const exchangesActivePeriod = useSelector(getExchangesActivePeriod)
  const exchangesPeriod = useSelector(getExchangesPeriod)
  const exchangesMapDataArray = useSelector(getExchangesMapDataArrayRaw)
  const exceptionsDict = useSelector(getExchangesExceptionsDict)
  const exchangesGroup = useSelector(getExchangesGroup)
  const valueTop = useSelector(getExchangesTop)

  return (
    <div className={styles.filter}>
      <Globals
        currency={currency}
        globals={globals}
        period={exchangesActivePeriod.active}
      />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            className={styles.reset}
            onClick={() => {
              dispatch(resetExchangesFiltersAll())
              dispatch(resetSharedFiltersAction())
            }}
          >
            {intl.t('layouts.mobileFilterLayout.exchangesFilters.reset')}
          </div>

          <div className={styles.content}>
            <Checkbox
              checked={exchangesGroup !== 'all'}
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.onlyVerified'
              )}
              onClick={() =>
                dispatch(
                  setExchangesGroup(
                    exchangesGroup === 'all' ? 'Verified' : 'all'
                  )
                )
              }
            />

            <RadioGroupTabs
              label={intl.t('layouts.mobileFilterLayout.exchangesFilters.top')}
              options={exchangesFiltersOptions.top}
              value={valueTop}
              defaultValue={exchangesFiltersOptions.top}
              onChange={data => dispatch(setExchangesTop(data))}
              className={styles.item}
            />

            <SelectFilter
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.period'
              )}
              options={exchangesFiltersOptions.period}
              value={exchangesActivePeriod}
              defaultValue={exchangesFiltersOptions.period.active}
              action={data =>
                dispatch(
                  changeExchangesPeriod({ ...exchangesPeriod, active: data })
                )
              }
            />

            <LogarithmicRangeFilter
              maxValue={maxValueVolumeRange}
              value={exchangesRange}
              action={range => dispatch(setExchangesRange(range))}
              reset={range => dispatch(resetExchangesRange(range))}
              isMobile={true}
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.volume',
                {
                  currency: currency,
                }
              )}
              className={styles.item}
            />

            <RangeFilter
              maxValue={maxValueMarkets}
              withInputs={true}
              value={exchangesMarketRange}
              action={markets => dispatch(setExchangesMarketsRange(markets))}
              isMobile={true}
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.markets'
              )}
              className={styles.item}
            />

            <SelectFilter
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.baseCurrency'
              )}
              options={sharedFiltersOptions.currency}
              value={currency}
              defaultValue={sharedFiltersDefaults.currency}
              action={data => dispatch(setSharedCurrency(data))}
            />
            <MultiSelectFilter
              label={intl.t(
                'layouts.mobileFilterLayout.exchangesFilters.hideFrom'
              )}
              mapDataArray={exchangesMapDataArray}
              currentItemsDict={exceptionsDict}
              currency={currency}
              entity={ENTITY.EXCHANGE}
              addItem={data => dispatch(addExchangesException(data))}
              deleteItem={data => dispatch(deleteExchangesException(data))}
              clear={() => dispatch(resetExchangesExceptions())}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ExchangesFilters
