import React, { useRef, useState, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import { useNFTCollectionSearch } from 'src/state/NFTCollections'
import { SearchResult } from './components/SearchResult'
import styles from './styles.module.scss'

export const Search = ({ className }) => {
  const [searchText, setSearchText] = useState('')
  const searchRef = useRef(null)
  const [focused, setFocused] = useState(false)

  const { data } = useNFTCollectionSearch({
    filter__name_search: searchText,
  })

  const onFocus = () => setFocused(true)

  const handleChange = event => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setFocused(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [searchRef])

  return (
    <div ref={searchRef} className={clsx(styles.search, className)}>
      <input
        className={styles.input}
        type='text'
        placeholder={'Search...'}
        onChange={handleChange}
        onFocus={onFocus}
        value={searchText}
      />
      {searchText && (
        <Image
          className={styles.closeIcon}
          src='/assets/icons/icon-close.svg'
          width='16'
          height='16'
          alt='Search icon'
          onClick={() => setSearchText('')}
        />
      )}
      {searchText.length >= 2 && focused && <SearchResult data={data?.data} />}
    </div>
  )
}
