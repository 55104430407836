import { useState, useEffect, forwardRef } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import styles from './styles.module.scss'

const PIXELS_TO_SHOW = 200

export const ScrollToTop = forwardRef(({ overflow = false, list }, ref) => {
  const [show, setShow] = useState(false)

  const scrollTop = () => {
    const element = overflow ? ref.current : window
    element.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }

  useEffect(() => {
    const element = overflow ? ref.current : window

    const handleScroll = () => {
      const scrollTop = overflow ? element.scrollTop : window.pageYOffset

      if (scrollTop > PIXELS_TO_SHOW) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
    element.addEventListener('scroll', handleScroll)
    return () => element.removeEventListener('scroll', handleScroll)
  }, [ref, overflow])

  return (
    <div
      onClick={scrollTop}
      className={clsx(styles.btn, { [styles.show]: show, [styles.list]: list })}
    >
      <Image
        src='/assets/icons/icon-arrow-to-top.svg'
        width='20'
        height='14'
        alt='Arrow icon'
      />
    </div>
  )
})

export default ScrollToTop
