import clsx from 'clsx'
import dynamic from 'next/dynamic'
import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'

const WidgetLogo = dynamic(() => import('./WidgetLogo'), { ssr: false })

export const Logo = ({ className = undefined, widget }) => {
  if (widget) return <WidgetLogo className={className} widget={widget} />

  return (
    <Link
      className={clsx(styles.logo, className)}
      href='/'
      prefetch={false}
      rel='noopener'
      passHref
    >
      <Image
        className={clsx(styles.boxText, widget)}
        alt='cryptocurrency widget, price, heatmap'
        src='/assets/logo.svg'
        width='124'
        height='18'
        layout='fixed'
      />
    </Link>
  )
}

export default Logo
