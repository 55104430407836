import { useState, useEffect } from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import { Toggle } from 'src/components/Toggle'
import { TooltipWrapper } from 'src/components/TooltipWrapper'
import { FiltersTrigger, ResetFilters } from './components'
import { getCurrentEntity } from 'src/redux/selectors'
import ViewTypeSwitcher from 'src/components/ViewTypeSwitcher/'
import styles from './styles.module.scss'
import FlashIcon from 'public/assets/icons/icon-flash.svg'
import FlashIconGreen from 'public/assets/icons/icon-flash-green.svg'
import { NewShareButton } from 'src/components/NewShareButton'
import MenuBelow from 'src/components/Header/components/MenuBelow'
import { useListPinned } from 'src/state'
import Link from 'next/link'
import useWidth from 'src/hooks/useWidth'

const renderLatestItem = item => {
  const baseUrl = 'https://coin360.com/list/'

  return (
    <div className={styles.item}>
      <Link
        className={styles.titleItem}
        rel='noopener noreferrer nofollow'
        target='_blank'
        href={`${baseUrl}${item?.data?.attributes?.slug}`}
        passHref
      >
        {item?.data?.attributes?.title}
      </Link>
      <Link href={`${baseUrl}${item?.data?.attributes?.slug}`} passHref>
        <div className={styles.info}>
          <div className={styles.latestName}>COIN360</div>
          <div className={styles.readNow}>Read now!</div>
        </div>
      </Link>
    </div>
  )
}

export const FiltersLayout = ({
  openModal,
  children,
  coinsPage = false,
  classNames,
  flashButton = false,
  isSidebarOpened = false,
}) => {
  const [toggler, setToggler] = useState(true)
  const entity = useSelector(getCurrentEntity)
  const { getWidthWindow, isMobile } = useWidth()
  const [leftBarResponsive, setLeftBarResponsive] = useState(false)
  const fixWidth = 830
  const [dinamicWidthLeftBar, setDinamicWidthLeftBar] = useState(fixWidth)
  const pinnedList = useListPinned()

  useEffect(() => {
    // Remove window.autoUpdater in the future if unnecessary
    window.autoUpdater?.toggleAutoUpdate(toggler)
  }, [toggler])

  useEffect(() => {
    if (!pinnedList) {
      // dispatch(getListPinned()) // Uncomment if needed
    }
  }, [pinnedList])

  useEffect(() => {
    const width = isSidebarOpened ? getWidthWindow - 315 : getWidthWindow
    width < 1200 ? setLeftBarResponsive(true) : setLeftBarResponsive(false)

    if (width < 1090 && isSidebarOpened) {
      setDinamicWidthLeftBar(fixWidth - (1090 - width))
    }
  }, [isSidebarOpened, getWidthWindow])

  return (
    <div className={clsx(styles.filters, classNames)}>
      <div className={styles.menu}>
        <MenuBelow />

        <div className={styles.filterButtons}>{children}</div>
        <button className={styles.filterButton} onClick={openModal}>
          <p className={styles.buttonText}>Filters</p>
          <Image
            className={styles.imagee}
            src='/assets/icons/icon-filter.svg'
            width='20'
            height='20'
            alt='filter'
          />
        </button>
        <div className={styles.resetButton}>
          <ResetFilters entity={entity} />
        </div>
      </div>

      {/* Conditionally render pinnedList for laptops and smaller screens */}
      {getWidthWindow <= 1440 && pinnedList && (
        <div className={styles.newsContainer}>
          {renderLatestItem(pinnedList)}
        </div>
      )}

      <div
        className={clsx(styles.block, {
          [styles.rigthBar]: flashButton && !coinsPage,
        })}
      >
        {flashButton && !coinsPage && (
          <Toggle
            active={toggler}
            onClick={() => setToggler(!toggler)}
            icon={<FlashIcon />}
            iconActive={<FlashIconGreen />}
            className={styles.flashClassName}
            classNameIcon={styles.flashIconClassName}
          />
        )}
        <ViewTypeSwitcher />
        {!isMobile && <NewShareButton className={styles.share} />}
      </div>
    </div>
  )
}

export default FiltersLayout
