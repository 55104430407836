export const selectStyles = {
  container: provided => ({
    ...provided,
    borderBottom: '1px solid rgba(255, 255, 255, 0.3)',
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    backgroundColor: 'transparent',
    minHeight: '30px',
    boxShadow: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'rgba(255, 255, 255, 0.4)',
  }),
  valueContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  indicatorSeparator: () => ({}),
  indicatorsContainer: provided => ({
    ...provided,
    padding: 0,
  }),
  input: provided => ({
    ...provided,
    margin: 0,
    padding: 0,
    color: '#e6e6e6',
  }),
  menu: provided => ({
    ...provided,
    backgroundColor: '#333236',
    marginTop: '1px',
    borderRadius: 0,
    height: '150px',
  }),
  menuList: provided => ({
    ...provided,
    padding: 0,
    overflowY: 'unset',
    maxHeight: '150px',
  }),
  option: provided => ({
    ...provided,
    padding: '6px 6px 6px 10px',
    background: 'transparent',
    lineHeight: '16px',
    color: 'rgba(255, 255, 255, 0.6)',
  }),
  multiValue: provided => ({
    ...provided,
    background: 'hsla(0,0%,100%,.1)',
    fontSize: '0.8rem',
    lineHeight: '1.2em',
    padding: '8px',
    textTransform: 'capitalize',
    color: '#e6e6e6',
  }),
  multiValueLabel: () => ({
    padding: '0',
    color: '#e6e6e6',
  }),
}
