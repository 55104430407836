import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetSharedFiltersAction } from 'src/redux/actions'
import {
  getCoinsPeriod,
  getCoinsDisplay,
  getCoinsTop,
  getCoinsActivePeriod,
  getDependsOnByEntity,
  getCoinsRange,
  getMapDataMaxValues,
  getMapDataArrayRaw,
  getCoinsExceptionsDict,
  getCoinsRanking,
  getCoinsTag,
} from 'src/redux/selectors'
import { coinsFiltersDefaults, sharedFiltersDefaults } from 'src/redux/reducers'
import {
  resetCoinsFiltersAll,
  changeCoinsPeriod,
  deleteException,
  setCoinsDisplay,
  setCoinsTop,
  setCoinsDependsOn,
  addException,
  setSharedCurrency,
  setCoinsRange,
  resetCoinsExceptions,
  resetCoinsRange,
  setCoinsRanking,
  setCoinsTag,
} from 'src/redux/thunks'
import { getFieldOfDataObject } from 'src/utils/currency'
import { sharedFiltersOptions, coinsFiltersOptions, ENTITY } from 'src/consts'
import { RadioGroupTabs } from 'src/components/RadioGroupTabs'
import { LogarithmicRangeFilter } from 'src/components/LogarithmicRangeFilter'
import { Globals, MultiSelectFilter, SelectFilter } from './components'
import { intl } from 'src/i18n'

import styles from './styles.module.scss'

const rangeTitle = dependsOn => {
  return {
    volume: intl.t('layouts.mobileFilterLayout.coinFilters.volumeRange'),
    marketCap: intl.t('layouts.mobileFilterLayout.coinFilters.marketCapRange'),
  }[dependsOn]
}

export const CoinFilters = ({ currency, globals }) => {
  const dispatch = useDispatch()
  const coinsPeriod = useSelector(getCoinsPeriod)
  const coinsDisplay = useSelector(getCoinsDisplay)
  const coinsTop = useSelector(getCoinsTop)
  const coinsActivePeriod = useSelector(getCoinsActivePeriod)
  const dependsOnByEntity = useSelector(getDependsOnByEntity)
  const coinsRange = useSelector(getCoinsRange)
  const maxValue = getFieldOfDataObject(
    useSelector(getMapDataMaxValues),
    useSelector(getDependsOnByEntity),
    Math.round
  )
  const mapDataArray = useSelector(getMapDataArrayRaw)
  const exceptionsDict = useSelector(getCoinsExceptionsDict)
  const coinsRanking = useSelector(getCoinsRanking)
  const coinsTag = useSelector(getCoinsTag)

  return (
    <div className={styles.filter}>
      <Globals
        currency={currency}
        globals={globals}
        period={coinsPeriod.active}
      />

      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div
            type='button'
            className={styles.reset}
            onClick={() => {
              dispatch(resetCoinsFiltersAll())
              dispatch(resetSharedFiltersAction())
            }}
          >
            {intl.t('layouts.mobileFilterLayout.coinFilters.reset')}
          </div>

          <div className={styles.content}>
            <SelectFilter
              label={intl.t('layouts.mobileFilterLayout.coinFilters.ranking')}
              options={coinsFiltersOptions.ranking}
              value={coinsRanking}
              defaultValue={coinsFiltersDefaults.ranking}
              action={data => dispatch(setCoinsRanking(data))}
            />

            <SelectFilter
              label={intl.t('layouts.mobileFilterLayout.coinFilters.tag')}
              options={coinsFiltersOptions.tag}
              value={coinsTag}
              defaultValue={coinsFiltersDefaults.tag}
              action={data => dispatch(setCoinsTag(data))}
            />

            {/*
            <SelectFilter
              label={intl.t(
                'layouts.mobileFilterLayout.coinFilters.displayMap'
              )}
              options={coinsFiltersOptions.display}
              value={coinsDisplay}
              defaultValue={coinsFiltersDefaults.display}
              action={data => dispatch(setCoinsDisplay(data))}
            />
            */}

            <SelectFilter
              label={intl.t('layouts.mobileFilterLayout.coinFilters.top')}
              options={coinsFiltersOptions.top}
              value={coinsTop}
              defaultValue={coinsFiltersDefaults.top}
              action={data => dispatch(setCoinsTop(data))}
            />

            <SelectFilter
              label={intl.t('layouts.mobileFilterLayout.coinFilters.period')}
              options={coinsFiltersOptions.period}
              value={coinsActivePeriod}
              defaultValue={coinsFiltersDefaults.period.active}
              action={data =>
                dispatch(changeCoinsPeriod({ ...coinsPeriod, active: data }))
              }
            />

            <SelectFilter
              label={intl.t(
                'layouts.mobileFilterLayout.coinFilters.baseCurrency'
              )}
              options={sharedFiltersOptions.currency}
              value={currency}
              defaultValue={sharedFiltersDefaults.currency}
              action={data => dispatch(setSharedCurrency(data))}
            />

            <RadioGroupTabs
              label={intl.t('layouts.mobileFilterLayout.coinFilters.blockSize')}
              options={coinsFiltersOptions.dependsOn}
              value={dependsOnByEntity}
              defaultValue={coinsFiltersDefaults.dependsOn}
              onChange={data => dispatch(setCoinsDependsOn(data))}
              className={styles.item}
            />

            <LogarithmicRangeFilter
              maxValue={maxValue}
              value={coinsRange}
              isMobile
              label={`${rangeTitle(dependsOnByEntity)} (${currency})`}
              className={styles.item}
              action={range => dispatch(setCoinsRange(range))}
              reset={range => dispatch(resetCoinsRange(range))}
            />

            <MultiSelectFilter
              label={intl.t('layouts.mobileFilterLayout.coinFilters.hideFrom')}
              mapDataArray={mapDataArray}
              currentItemsDict={exceptionsDict}
              currency={currency}
              addItem={data => dispatch(addException(data))}
              deleteItem={data => dispatch(deleteException(data))}
              clear={() => dispatch(resetCoinsExceptions())}
              entity={ENTITY.COIN}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CoinFilters
