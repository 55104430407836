import clsx from 'clsx'
import { getValues } from 'src/utils/currency'
import { formatChangeValue } from 'src/utils/number/number'
import styles from './styles.module.scss'

export const Globals = ({ currency, globals, period }) => {
  const globalMarketCap = getValues('marketCap', true)(currency, globals)
  const globalVolume = getValues('volume', true)(currency, globals)
  const globalChangeMarketCap = globals.changeMarketCap
    ? formatChangeValue(globals.changeMarketCap)
    : 0
  const globalChangeVolume = globals.changeVolume
    ? formatChangeValue(globals.changeVolume)
    : 0

  const getColorClassName = globalChangeValue => {
    if (globalChangeValue > 0) {
      return styles.green
    }

    if (globalChangeValue < 0) {
      return styles.red
    }

    return styles.gray
  }

  if (!(period && typeof period === 'string')) {
    period = ''
  } else {
    switch (period.toLowerCase()) {
      case '1h':
      case '24h':
      case '7d':
      case '30d':
        period = period
        break
      case 'custom':
        period = ''
        break
      default:
        period = ''
    }
  }

  return (
    <div className={styles.globals}>
      <div
        className={clsx(
          styles.item,
          getColorClassName(globals.changeMarketCap || 0)
        )}
      >
        <div className={styles.label}>
          Market Cap
          <span className={styles.diff}>{globalChangeMarketCap} %</span>
        </div>
        <div className={styles.body}>
          {globalMarketCap ? globalMarketCap : '?'}
        </div>
      </div>
      <div
        className={clsx(
          styles.item,
          getColorClassName(globals.changeVolume || 0)
        )}
      >
        <div className={styles.label}>
          Vol {period}
          <span className={styles.diff}>{globalChangeVolume} %</span>
        </div>
        <div className={styles.body}>{globalVolume ? globalVolume : '?'}</div>
      </div>
    </div>
  )
}

export default Globals
