import Link from 'next/link'
import Image from 'next/image'
import styles from './styles.module.scss'

export const FooterNavbar = () => {
  return (
    <div className={styles.footer}>
      <Link
        className={styles.buttonFooter}
        href={'mailto:contact@coin360.com'}
        passHref
      >
        <Image
          src={`/assets/icons/icon-mail.svg`}
          width='22'
          height='22'
          alt='mail icon'
        />
      </Link>
      <Link
        className={styles.buttonFooter}
        href={'https://t.me/COIN360Channel'}
        passHref // legacyBehavior and <a target='_blank'>
      >
        <Image
          src={`/assets/social/icon-telegram.svg`}
          width='22'
          height='22'
          alt='telegram icon'
        />
      </Link>
      <Link
        className={styles.buttonFooter}
        href={'https://twitter.com/coin360com'}
        passHref
      >
        <Image
          src={`/assets/social/icon-twitter.svg`}
          width='22'
          height='22'
          alt='twitter icon'
        />
      </Link>
      <Link href={'/newsletter'} className={styles.buttonFooter} passHref>
        <Image
          src={`/assets/icons/icon-newsletter.svg`}
          width='22'
          height='22'
          alt='newsletter icon'
        />
      </Link>
    </div>
  )
}
