import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import Subscription from '../Subscription'
import MobileMenuItem from '../MobileMenuItem'
import { getAllMenuItemsMobile } from '../../helpers/menuItemsMobile'
import { combinedAnalytics } from 'src/utils/common'
import Session from 'supertokens-auth-react/recipe/session'
import { CurrencyLanguageModal } from 'src/components/CurrencyLanguageModal'
import GlobalSearch from '../GlobalSearch'
import { ProfileHeader } from 'src/components/Auth'
import { MobileDropdown } from '../MobileDropdown'
import { FooterNavbar } from 'src/components/FooterNavbar'
import { Logo } from 'src/components/Logo'
import { DrawerWrapper } from 'src/components/Drawer'
import LayoutMenuButton from 'src/components/Banners/LayoutMenuButton'
import { AuthModal } from 'src/components/AuthModal'
import { SessionAuth } from 'supertokens-auth-react/recipe/session'
import { signOut } from 'supertokens-auth-react/recipe/thirdpartyemailpassword'
import { getUserDetails } from 'src/services/userServices'
import styles from './styles.module.scss'

export const MobileHeader = ({ className, switcherWatchlist = false }) => {
  const [userDetails, setUserDetails] = useState()
  const [active, setActive] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const sessionContext = Session.useSessionContext()
  const router = useRouter()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getUserDetails()
        setUserDetails(data)
      } catch (error) {
        console.error('Error fetching user details:', error)
      }
    }

    fetchData()
  }, [])

  const toggleOpen = () => setActive(!active)
  const toggleFilters = () =>
    switcherWatchlist ? switcherWatchlist() : setShowFilters(!showFilters)

  const handleBurgerClick = () => {
    toggleOpen()
    combinedAnalytics('Menu', 'Menu', 'Display', active ? 'On' : 'Off')
  }

  const handleLogout = async () => {
    await signOut()
    window.location.reload()
  }

  return (
    <header className={clsx(styles.header, className)}>
      <div className={styles.left}>
        <Logo className={styles.logo} />
        <MobileDropdown />
      </div>
      <div className={styles.right}>
        <div className={styles.buttonsContainer}>
          <LayoutMenuButton
            horizontal={true}
            className={styles.layoutMenuButton}
          />
          <GlobalSearch />
          <div className={styles.burger} onClick={handleBurgerClick}>
            <Image
              src='/assets/icons/icon-burger.svg'
              width='32'
              height='32'
              alt='Burger icon'
            />
          </div>
        </div>
      </div>

      <DrawerWrapper
        open={active}
        onClose={toggleOpen}
        placement='right'
        className={styles.newMobileMenu}
        width='264'
      >
        <section className={styles.hamburgerMenu}>
          <div className={styles.closeMenu}>
            <Image
              alt='close'
              src='/assets/icons/icon-close-cross.svg'
              width='22'
              height='22'
              onClick={toggleOpen}
            />
          </div>
          {sessionContext.doesSessionExist && (
            <Link href={'/profile'} passHref>
              <div className={styles.avatarContainer}>
                <SessionAuth>
                  <ProfileHeader userInfo={sessionContext.accessTokenPayload} />
                </SessionAuth>
                <div className={styles.usernameContainer}>
                  <p className={styles.username}>
                    {userDetails &&
                    userDetails.settings &&
                    userDetails.settings.nickname
                      ? userDetails.settings.nickname
                      : 'No username'}
                  </p>
                  <p className={styles.profile}>Your Profile</p>
                </div>
                <Image
                  alt='close'
                  src='/assets/icons/icon-arrow-next.svg'
                  width='24'
                  height='24'
                />
              </div>
            </Link>
          )}
          <div className={styles.content}>
            <div className={styles.menuContainer}>
              {getAllMenuItemsMobile().map(item => {
                const currentLocationPath = router.pathname.split('/')[1]
                const isCurrentPage = item.key === currentLocationPath

                return (
                  <MobileMenuItem
                    key={item.key}
                    path={item.path}
                    type={item.type}
                    title={item.title}
                    current={isCurrentPage}
                    highlighted={item.highlighted}
                    childrenMenuItems={item.subMenu}
                  />
                )
              })}
              <Subscription />
            </div>
            <footer className={styles.footer}>
              <div className={styles.buttonsModalContainer}>
                <CurrencyLanguageModal buttonClass={styles.buttonCurrency} />
                <div className={styles.authContainer}>
                  {sessionContext.doesSessionExist ? (
                    <span
                      className={styles.buttonLogout}
                      onClick={handleLogout}
                    >
                      <Image
                        alt='close'
                        src='/assets/icons/icon-log-out.svg'
                        width='22'
                        height='22'
                      />
                      Log out
                    </span>
                  ) : (
                    <AuthModal buttonClass={styles.buttonAuth} />
                  )}
                </div>
              </div>

              <FooterNavbar />
            </footer>
          </div>
        </section>
      </DrawerWrapper>
    </header>
  )
}

export default MobileHeader
