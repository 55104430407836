import Image from 'next/image'
import Link from 'next/link'
import styles from './styles.module.scss'

export const Item = ({ item, key }) => {
  return (
    <Link href={`/nft/${item.address}`} passHref>
      <div key={key} className={styles.item}>
        <Image
          src={`/site-api/thumbnails?url=${encodeURIComponent(
            item.imgUrl
          )}&size=30`}
          width='30'
          height='30'
          alt={item.name}
        />
        <div className={styles.title}>{item.name}</div>
      </div>
    </Link>
  )
}
