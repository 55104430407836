import clsx from 'clsx'
import Image from 'next/image'
import { CustomDropdown } from 'src/components/CustomDropdown'
import styles from './styles.module.scss'
import { useState } from 'react'
import { combinedAnalytics } from 'src/utils/common'

export const BaseCurrencyFilter = ({ options, value, action }) => {
  const [isOpen, setIsOpen] = useState(false)
  const changeOpen = val => setIsOpen(val)

  const handleClick = v => () => {
    setIsOpen(false)
    combinedAnalytics(
      `Filter`,
      'Filter',
      'Interaction:change',
      `Base currency:${v}`
    )

    action && action(v)
  }

  return (
    <div className={styles.currenciesContainer}>
      <CustomDropdown
        showArrow
        isOpen={isOpen}
        setIsOpen={changeOpen}
        currencyLogo={value}
        listClassName={styles.currencyDropdown}
      >
        <div className={styles.list}>
          {options.map(option => {
            const isChecked = value === option.value
            return (
              option.fiat && (
                <div
                  key={`option-${option.value}`}
                  onClick={handleClick(option.value)}
                  className={clsx(styles.item, { [styles.active]: isChecked })}
                >
                  <Image
                    src={`/assets/currency/${option.value}-filter-basecurrency.svg`}
                    width='17'
                    height='17'
                    alt={option.value}
                  />
                  <div>
                    {isChecked} {option.label}
                  </div>
                </div>
              )
            )
          })}
        </div>
        <div className={styles.list}>
          <div className={styles.fiat}>
            {options.map(option => {
              const isChecked = value === option.value
              return (
                !option.fiat && (
                  <div
                    key={`option-${option.value}`}
                    onClick={handleClick(option.value)}
                    className={clsx(styles.item, {
                      [styles.active]: isChecked,
                    })}
                  >
                    <Image
                      src={`/assets/currency/${option.value}-filter-basecurrency.svg`}
                      width='17'
                      height='17'
                      alt={option.value}
                    />
                    <div>
                      {isChecked} {option.label}
                    </div>
                  </div>
                )
              )
            })}
          </div>
        </div>
      </CustomDropdown>
    </div>
  )
}

export default BaseCurrencyFilter
